.sidebar{
    flex: 3;
    height: calc(100vh - 50px);
    position: sticky;
    top: 50px;
    overflow-y: scroll;
}

/*to manipulate the scrollbar*/
::-webkit-scrollbar{
    width: 5px;
}

::-webkit-scrollbar-track{
    background-color: white;
}

::-webkit-scrollbar-thumb{
    background-color: gray;
}


/* end of scrollbar manipulation */

.sidebarWrapper{
    padding: 20px;
    text-align: left;
  
}

.sidebarList{
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebarListItem{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.sidebarIcon{
    margin-right: 15px;
}

.sidebarButton{
    width: 150px;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    background-color: gray;
    color: white ;
}

.sidebarHr{
    margin: 20px 0;
 }

 .sidebarFriendImg{
    padding: 0;
    margin: 0;
    margin-right: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    object-fit: cover;
    
 }

 .sidebarFriend{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 0;
    list-style: none;
 }

 .sidebarFriendList{
    list-style: none;
    margin: 0;
    padding: 0;
 }






 