.feed{
    flex: 5.5;
}

/* .feeder{
    overflow-y: scroll;
}

/*to manipulate the scrollbar*/
::-webkit-scrollbar{
    width: 5px;
}

::-webkit-scrollbar-track{
    background-color: white;
}

::-webkit-scrollbar-thumb{
    background-color: gray;
}



.feedWrapper{
    margin: 10px;
}



