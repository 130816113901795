.rightbar{
    flex: 1;
    width: 300px;
} 

.birthdayText b{
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
     
}

.birthdayContainer{
    display: flex;
    align-items: center;

}

.rightbarWrapper{
    padding: 20px 20px 0 0;
}

.birthdayImg{
    width: 40px;
    height: 40px;
}

.rightbarAd{
    width: 100%;
    border-radius: 10px;
    margin: 30px 0;
}

.rightbarFiendList{
    margin: 0;
    padding: 0;
    list-style: none;
}

.rightbarTitle{
    margin-bottom: 20px;
}

.rightBarFriend{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.rightBarProfileImgContainer{
    margin-right: 10px;
    position: relative;
}

.rightbarProfileImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.rightbarOnline{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: limegreen;
    position: absolute;
    border: 2px solid ;
    border-color: white;
    /* margin-left: -10px; */ /*can also be used in place of the below*/
    top: -2px;
    right: 0;
}

.rightbarUsername{
    font-weight: 500;

}