.post{
    width: 90%;
    border-radius: 10px;
    -webkit-box-shadow: 8px 5px 15px 6px rgba(0,0,0,0.46); 
    box-shadow: 8px 5px 15px 6px rgba(0,0,0,0.46);    
    margin: 30px, 0;
    margin-left: 20px;
    margin-bottom: 30px;
}

.postWrapper{
    padding: 10px;
}

.postTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.postTopLeft{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.postProfileImg{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer ;
}

.postUsername{
    font-size: 15px;
    font-weight: 500;
    margin: 0 10px;
}

.postDate{
    font-size: 12px;
}

.postCenter{
    width: 80%;
}

.postCenterImg{
    max-width: 125%;
    max-height: 500px;
    object-fit: contain;
}

.postBottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.postBottomLeft{
    display: flex;
    align-items: center;
}

.likeIcon{
    width: 24px;
    height: 24px;
    margin-right: 5px;
    cursor: pointer;
}

.postLikeCounter{
    font-size: 15px;
}

.postCommentText{
    cursor: pointer;
    border-radius: 1px dashed gray;
    font-size: 15px;
}

.postCenter{
    margin-bottom: 10px;
}

