
.homeContainer{
    display: flex;
    width: 100%;
    text-align: left;
}

@media screen and (max-width:600px) {
    div.moni{
        display:none;
    }
}