.feedShare{
    margin:20px;
    width: 90%;
    height: 170px;
    border-radius: 10px;
    border-width: 10px;
    -webkit-box-shadow: 8px 5px 15px 6px rgba(0,0,0,0.46); 
    box-shadow: 8px 5px 15px 6px rgba(0,0,0,0.46);
}

.shareTop{
    display: flex;
    align-items: center;
}


.shareWrapper{
    padding: 10px;
}

.shareProfileImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.shareInput{
    border: none;
    width: 80%;
}

.shareInput:focus{
    outline: none;
}

.shareHr{
    margin: 20px;
}

.shareButton{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 15px;
}

.shareOptions{
    display: flex;
    margin-left: 15px;
}

.shareOption{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
}

 .shareIcon{
    margin-right: 1px;
 }

 .instagramIcon{
    width: 20px;
    height: 20px;
    margin-right: 3px;

 }

 .instagramIcons{
    width: 15px;
    height: 20px;
    margin-right: 3px;
 }

 .shareOptionText{
    font-size: 14px;
    font-weight: 500;
 }

 .shareButtonside{
    border: none;
    padding: 7px;
    border-radius: 5px;
    background-color: green;
    font-weight: 500;
    cursor: pointer;
    color: white;
 }