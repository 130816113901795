.topbarContainer{
    height: 50px;
    width: 100%;
    background-color: #1877f2;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
}

.topbarLeft{
    flex: 3;
}

.searchbar{
    width: 100%;
    height: 30px;background-color: white;
    border-radius: 30px;
    display: flex;
    align-items: center;
}

.searchIcon{
    font-size: 20px !important;
    margin-left: 10px;
}

.searchinput{
    border: none;
    width: 70%;

}

.searchinput:focus{
    outline: none;
}



.logo{
    font-size: 24px;
    margin-left:20px;
    font-weight: bold;
    color: white;
    cursor: pointer;

}

.topbarCenter{
    flex: 5;
}

.topbarRight {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
}

.topbarLink{
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    color: white;
}

.topbarIcons{
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.topbarIconItem{
    margin-right: 15px;
    cursor:pointer;
    position:relative;
}

.topbarIconBadge{
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50px;
    color: white;
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.topbarimg{
     width:32px;
     height:32px;
     border-radius: 20px;
     object-fit: cover;
     cursor: pointer;
}

.topbarIconBadge{
    color: white;
}


